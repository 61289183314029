@use "../config/" as *;
.main-wrap {
    margin-top: var(--header-height);
}
.waf-breadcrumb {
    display: none;
}
.waf-register-profile {
    @extend .full-width;
    .form-list {
        @extend %px-3;
    }
    .form-head {
        border-bottom: .1rem solid clr(neutral-100, 5);
        @extend %flex-n-c;
        @extend %pb-2;
        .btn-skip {
            order: 1;
        }
    }
    .form-element {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
            -webkit-text-fill-color: clr(neutral-1000);
            -webkit-box-shadow: 0 0 0 40rem clr(neutral-50) inset;
        }
    }
    .btn-fill {
        width: 100%;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-register-profile {
        .layout-wrapper {
            max-width: unset;
        }
        .btn-fill {
            width: 30rem;
        }
        .form {
            &-banner {
                height: calc(var(--window-inner-height) - var(--header-height));
            }
            &-list {
                overflow-y: auto;
                height: calc(var(--window-inner-height) - var(--header-height));
                @include custom-scroll();
            }
            &-wrapper {
                padding: 5rem 10rem;
            }
            &-area {
                display: grid;
                grid-template-columns: 45% 55%;
            }
            &-wrap-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
}