@use "../config/" as *;
.main-wrap {
    margin-top: var(--header-height);
}
.waf-breadcrumb {
    display: none;
}
.waf-login {
    @extend .full-width;
    .login-wrap {
        @extend %w-100;
    }
    .form-head {
        flex-wrap: wrap;
        @extend %flex-n-fs;
    }
    .step-1 {
        .form-step2 {
            @extend %d-none;
        }
    }
    .step-2 {
        .form-step1 {
            @extend %d-none;
        }
    }
    .form-group {
        button[type="button"]:disabled {
            @extend %secondary-300-bg;
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-login {
        .layout-wrapper {
            max-width: unset;
        }
        .form {
            &-banner {
                min-height: calc(var(--window-inner-height) - var(--header-height));
            }
            &-list {
                overflow-y: auto;
                min-height: calc(var(--window-inner-height) - (var(--header-height)));
                @include custom-scroll();
            }
            &-wrapper {
                padding: 5rem 10rem;
            }
            &-area {
                display: grid;
                grid-template-columns: 45% 55%;
            }
            &-wrap-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
            }
        }
        .btn-fill {
            width: 30rem;
        }
    }
}